export default [
  {
    path: '',
    name: 'Goods',
    component: () => import('./List.vue'),
  },
  {
    path: 'create',
    name: 'GoodsCreate',
    component: () => import('./Create.vue'),
  },
  {
    path: ':id/edit',
    name: 'GoodsEdit',
    component: () => import('./Edit.vue'),
    props: true,
  },
  {
    path: 'sort',
    name: 'SortGoods',
    component: () => import('./SortProducts.vue'),
  },
]
