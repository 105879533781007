var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "header",
        { staticClass: "mb-8" },
        [_c("BreadCrumb", { attrs: { config: _vm.config } })],
        1
      ),
      _c(
        "el-card",
        [
          _vm.data
            ? [
                !_vm.config.tabs
                  ? _c("DetailItems", {
                      attrs: { fields: _vm.config.fields, data: _vm.data },
                    })
                  : _c("TabDetail", {
                      attrs: { config: _vm.config, data: _vm.data },
                    }),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }