export default [
  {
    path: '',
    name: 'users',
    component: () => import('./List.vue'),
  },
  {
    path: ':id',
    name: 'user_detail',
    component: () => import('./Detail.vue'),
    props: true,
  },
]
