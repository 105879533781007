var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form-item",
        {
          attrs: {
            rules: _vm.genRule(_vm.field),
            label: _vm.field.label,
            for: _vm.field.key,
            prop: _vm.field.key,
          },
        },
        [
          _vm.field.type == "number" && _vm.field.unit
            ? _c("el-input", {
                attrs: { id: _vm.field.key, controls: false },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "append",
                      fn: function () {
                        return [_vm._v(" " + _vm._s(_vm.field.unit) + " ")]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  662233716
                ),
                model: {
                  value: _vm.form[_vm.field.key],
                  callback: function ($$v) {
                    _vm.$set(_vm.form, _vm.field.key, $$v)
                  },
                  expression: "form[field.key]",
                },
              })
            : _vm.field.type == "number"
            ? _c("el-input-number", {
                attrs: {
                  id: _vm.field.key,
                  min: _vm.field.min,
                  max: _vm.field.max,
                },
                model: {
                  value: _vm.form[_vm.field.key],
                  callback: function ($$v) {
                    _vm.$set(_vm.form, _vm.field.key, $$v)
                  },
                  expression: "form[field.key]",
                },
              })
            : _vm.field.type == "image"
            ? _c("ImageUploader", {
                attrs: { id: _vm.field.key, multiple: _vm.field.multiple },
                model: {
                  value: _vm.form[_vm.field.key],
                  callback: function ($$v) {
                    _vm.$set(_vm.form, _vm.field.key, $$v)
                  },
                  expression: "form[field.key]",
                },
              })
            : _vm.field.type == "rich_text"
            ? _c("Editor", {
                attrs: { id: _vm.field.key },
                model: {
                  value: _vm.form[_vm.field.key],
                  callback: function ($$v) {
                    _vm.$set(_vm.form, _vm.field.key, $$v)
                  },
                  expression: "form[field.key]",
                },
              })
            : _vm.field.type == "checkbox-group"
            ? _c(
                "el-checkbox-group",
                {
                  attrs: {
                    id: _vm.field.key,
                    placeholder: _vm.field.placeholder,
                    disabled: _vm.disabled,
                  },
                  model: {
                    value: _vm.form[_vm.field.key],
                    callback: function ($$v) {
                      _vm.$set(_vm.form, _vm.field.key, $$v)
                    },
                    expression: "form[field.key]",
                  },
                },
                _vm._l(_vm.field.options || [], function (option) {
                  return _c(
                    "el-checkbox",
                    {
                      key: option[_vm.field.optionValue] || option.id,
                      attrs: {
                        label: option[_vm.field.optionValue] || option.id,
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(option[_vm.field.optionLabel] || option.name) +
                          " "
                      ),
                    ]
                  )
                }),
                1
              )
            : _vm.field.type == "select" || _vm.field.type == "multiselect"
            ? _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    id: _vm.field.key,
                    multiple: _vm.field.type == "multiselect",
                    placeholder: _vm.field.placeholder,
                    disabled: _vm.disabled,
                  },
                  model: {
                    value: _vm.form[_vm.field.key],
                    callback: function ($$v) {
                      _vm.$set(_vm.form, _vm.field.key, $$v)
                    },
                    expression: "form[field.key]",
                  },
                },
                [
                  Array.isArray(_vm.field.options)
                    ? _vm._l(_vm.field.options, function (option) {
                        return _c("el-option", {
                          key: option[_vm.field.optionValue],
                          attrs: {
                            value: option[_vm.field.optionValue] || option.id,
                            label: option[_vm.field.optionLabel] || option.name,
                          },
                        })
                      })
                    : _vm._l(
                        Object.entries(_vm.field.options),
                        function (option) {
                          return _c("el-option", {
                            key: option[_vm.field.optionValue],
                            attrs: {
                              value: option[_vm.field.optionValue] || option[0],
                              label: option[_vm.field.optionLabel] || option[1],
                            },
                          })
                        }
                      ),
                ],
                2
              )
            : _vm.field.type == "switch"
            ? _c("el-switch", {
                attrs: { id: _vm.field.key },
                model: {
                  value: _vm.form[_vm.field.key],
                  callback: function ($$v) {
                    _vm.$set(_vm.form, _vm.field.key, $$v)
                  },
                  expression: "form[field.key]",
                },
              })
            : _vm.field.type == "timeRange"
            ? _c("el-time-picker", {
                attrs: {
                  "is-range": "",
                  "value-format": "HH:mm:ss",
                  "range-separator": "至",
                  "start-placeholder": "开始时间",
                  "end-placeholder": "结束时间",
                  placeholder: "选择时间范围",
                },
                model: {
                  value: _vm.form[_vm.field.key],
                  callback: function ($$v) {
                    _vm.$set(_vm.form, _vm.field.key, $$v)
                  },
                  expression: "form[field.key]",
                },
              })
            : _vm.field.type == "timeSelect"
            ? [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      rules: _vm.genRule(_vm.field),
                      for: _vm.field.startTime,
                      prop: _vm.field.startTime,
                    },
                  },
                  [
                    _c("el-time-select", {
                      attrs: {
                        placeholder: "起始时间",
                        "picker-options": {
                          start: "08:00",
                          step: "00:30",
                          end: "18:00",
                        },
                      },
                      model: {
                        value: _vm.form[_vm.field.startTime],
                        callback: function ($$v) {
                          _vm.$set(_vm.form, _vm.field.startTime, $$v)
                        },
                        expression: "form[field.startTime]",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      rules: _vm.genRule(_vm.field),
                      for: _vm.field.endTime,
                      prop: _vm.field.endTime,
                    },
                  },
                  [
                    _c("el-time-select", {
                      attrs: {
                        placeholder: "结束时间",
                        "picker-options": {
                          start: "08:00",
                          step: "00:30",
                          end: "18:00",
                          minTime: _vm.form[_vm.field.startTime],
                        },
                        editable: false,
                      },
                      model: {
                        value: _vm.form[_vm.field.endTime],
                        callback: function ($$v) {
                          _vm.$set(_vm.form, _vm.field.endTime, $$v)
                        },
                        expression: "form[field.endTime]",
                      },
                    }),
                  ],
                  1
                ),
              ]
            : _vm.field.type == "date" || _vm.field.type == "datetime"
            ? _c("el-date-picker", {
                attrs: {
                  "value-format":
                    _vm.field.type == "date"
                      ? "yyyy-MM-dd"
                      : "yyyy-MM-dd HH:mm:ss",
                  type: _vm.field.type,
                  editable: false,
                },
                model: {
                  value: _vm.form[_vm.field.key],
                  callback: function ($$v) {
                    _vm.$set(_vm.form, _vm.field.key, $$v)
                  },
                  expression: "form[field.key]",
                },
              })
            : _vm.field.type == "textarea"
            ? _c("el-input", {
                attrs: {
                  id: _vm.field.key,
                  autosize: { minRows: 8 },
                  type: _vm.field.type,
                },
                model: {
                  value: _vm.form[_vm.field.key],
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      _vm.field.key,
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form[field.key]",
                },
              })
            : _c("el-input", {
                attrs: {
                  id: _vm.field.key,
                  disabled: _vm.disabled,
                  type: _vm.field.type,
                  placeholder: _vm.field.placeholder,
                  autocomplete: _vm.field.autocomplete,
                },
                model: {
                  value: _vm.form[_vm.field.key],
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      _vm.field.key,
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form[field.key]",
                },
              }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }