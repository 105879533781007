var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "root" },
      [
        _c(
          "SlickList",
          {
            staticClass: "list",
            attrs: { "lock-axis": "y", "helper-class": "sorting-item" },
            model: {
              value: _vm.items,
              callback: function ($$v) {
                _vm.items = $$v
              },
              expression: "items",
            },
          },
          _vm._l(_vm.items, function (item, index) {
            return _c(
              "SlickItem",
              {
                key: index,
                staticClass: "list-item darkgrey sort-item",
                attrs: { index: index },
              },
              [
                _c("span", [_vm._v(_vm._s(item.name || item.title))]),
                _vm._t("freedom", null, { data: item }),
                _c("span", { staticClass: "position-mark" }, [
                  _vm._v(_vm._s(index + 1)),
                ]),
              ],
              2
            )
          }),
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "flex justify-center" },
      [
        _c("el-button", { attrs: { size: "mini" }, on: { click: _vm.close } }, [
          _vm._v("取消"),
        ]),
        _c(
          "el-button",
          { attrs: { type: "primary", size: "mini" }, on: { click: _vm.save } },
          [_vm._v("确定")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }