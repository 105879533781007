var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.total
    ? _c("el-pagination", {
        staticClass: "center my-2",
        attrs: {
          total: _vm.total,
          "page-size": +_vm.pageSize,
          "page-sizes": [10, 20, 50],
          layout: "prev, pager, next, sizes",
          "current-page": +_vm.currentPage,
        },
        on: {
          "current-change": _vm.onPageChange,
          "size-change": _vm.onPageSizeChange,
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }