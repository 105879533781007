var render = function render(_c, _vm) {
  return _c("div", { class: ["py-4 flex", { "w-1/2": !_vm.props.block }] }, [
    _c("span", { staticClass: "data-title" }, [
      _vm._v(_vm._s(_vm.props.title)),
    ]),
    _c(
      "div",
      { staticClass: "data-value" },
      [
        _vm.props.value === undefined
          ? _vm._t("default")
          : _c("span", [_vm._v(_vm._s(_vm.props.value))]),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }