var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("VueEditor", {
    attrs: { "image-provider": _vm.imageProvider, width: _vm.width },
    model: {
      value: _vm.content,
      callback: function ($$v) {
        _vm.content = $$v
      },
      expression: "content",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }