var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.info
    ? _c(
        "div",
        _vm._l(_vm.fields, function (item) {
          return _c(
            "DetailItem",
            { key: item.key, attrs: { title: item.label } },
            [
              item.type == "date"
                ? _c("span", [
                    _vm._v(
                      " " + _vm._s(_vm._f("fmtDate")(_vm.info[item.key])) + " "
                    ),
                  ])
                : item.type == "rich_text"
                ? _c("div", {
                    staticStyle: { width: "350px" },
                    domProps: { innerHTML: _vm._s(_vm.info[item.key]) },
                  })
                : item.type == "boolean" && item.trueText
                ? [
                    _vm.info[item.key]
                      ? _c("span", [_vm._v(_vm._s(item.trueText))])
                      : _c("span", [_vm._v(_vm._s(item.falseText))]),
                  ]
                : item.type == "boolean"
                ? [
                    _vm.info[item.key]
                      ? _c("span", [_vm._v("是")])
                      : _c("span", [_vm._v("否")]),
                  ]
                : item.type == "image"
                ? _c("span", [
                    !item.multiple
                      ? _c("img", {
                          attrs: { src: _vm.info[item.key], width: "200" },
                        })
                      : _c(
                          "div",
                          { staticClass: "flex space-x-4" },
                          _vm._l(_vm.info[item.key], function (img) {
                            return _c("img", {
                              key: img,
                              staticClass: "rounded-lg",
                              attrs: { src: img, width: "200" },
                            })
                          }),
                          0
                        ),
                  ])
                : item.unit
                ? _c("span", [
                    _vm._v(
                      " " + _vm._s(_vm.info[item.key]) + _vm._s(item.unit) + " "
                    ),
                  ])
                : _c("span", [
                    _vm._v(
                      _vm._s(
                        (
                          item.formatter ||
                          function (d) {
                            return d
                          }
                        )(_vm.deepGet(_vm.info, item.key))
                      )
                    ),
                  ]),
            ],
            2
          )
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }