var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-menu",
    {
      staticClass: "menu",
      attrs: {
        collapse: _vm.navCollapse,
        router: "",
        "default-active": _vm.activePath,
      },
    },
    [
      _vm.navCollapse
        ? _c(
            "el-submenu",
            { attrs: { index: "/" } },
            [
              _c("template", { slot: "title" }, [
                _c("i", { staticClass: "el-icon-menu" }),
                _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                  _vm._v("首页"),
                ]),
              ]),
              _c(
                "el-menu-item",
                {
                  class: [{ "active-menu": _vm.$route.path === "/" }],
                  attrs: { index: "/" },
                },
                [
                  _c("template", { slot: "title" }, [
                    _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                      _vm._v("首页"),
                    ]),
                  ]),
                ],
                2
              ),
            ],
            2
          )
        : _c(
            "el-menu-item",
            {
              class: [{ "active-menu": _vm.$route.path === "/" }],
              attrs: { index: "/" },
            },
            [
              _c("template", { slot: "title" }, [
                _c("i", { staticClass: "el-icon-menu" }),
                _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                  _vm._v("首页"),
                ]),
              ]),
            ],
            2
          ),
      _vm._l(_vm.visibleMenus, function (menu) {
        return _c(
          "el-submenu",
          { key: menu.title, attrs: { index: menu.index } },
          [
            _c("template", { slot: "title" }, [
              _c("i", { class: "el-icon-" + menu.icon }),
              _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v(_vm._s(menu.title)),
              ]),
            ]),
            _vm._l(menu.menuItems, function (item) {
              return _c(
                "el-menu-item",
                {
                  key: item.path,
                  class: [
                    {
                      "active-menu": _vm.activePath == item.path,
                    },
                  ],
                  attrs: { index: item.path },
                },
                [_vm._v(" " + _vm._s(item.name) + " ")]
              )
            }),
          ],
          2
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }