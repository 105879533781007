export default {
  methods: {
    $validateForm(ref = 'form') {
      return new Promise((resolve, reject) => {
        const form = this.$refs[ref]
        form.validate((valid, errors) => {
          if (valid) {
            resolve()
          } else {
            reject(errors)
          }
        })
      })
    },
  },
}
