import PageList from './List'

export function createList(config) {
  return {
    functional: true,
    render() {
      return <PageList config={config} />
    },
  }
}
export default {
  createList,
}
