export const GoodsStatusEnum = {
  PENDING: '编辑',
  ON_SHELVE: '已上架',
  OFF_SHELVE: '已下架',
}
export const PENDING = 'PENDING'
export const ON_SHELVE = 'ON_SHELVE'
export const OFF_SHELVE = 'OFF_SHELVE'
export const DELETED = 'DELETED'

export const OrderStatus = {
  PENDING: '待付款',
  PAID: '待发货',
  SHIPPED: '已发货',
  RECEIVED: '已收货',
  CANCELLED: '已取消',
}
