<template>
  <div class="shadow bg-white rounded-md px-4 py-3">
    <slot />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
