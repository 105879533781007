var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.tokenFetched
        ? _c(
            "el-upload",
            {
              staticClass: "avatar-uploader",
              attrs: { action: "", "show-file-list": false },
            },
            [_c("i", { staticClass: "el-icon-plus avatar-uploader-icon" })]
          )
        : _c(
            "div",
            [
              _vm.multiple
                ? _c(
                    "div",
                    { staticClass: "flex space-x-4" },
                    [
                      _c(
                        "draggable",
                        {
                          staticClass: "flex space-x-4",
                          attrs: { "ghost-class": "ghost" },
                          model: {
                            value: _vm.images,
                            callback: function ($$v) {
                              _vm.images = $$v
                            },
                            expression: "images",
                          },
                        },
                        _vm._l(_vm.value, function (url) {
                          return _c(
                            "div",
                            { key: url, staticClass: "relative cursor-move" },
                            [
                              _c("img", {
                                staticClass: "w-[148px] h-[148px] rounded-md",
                                attrs: { src: url },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "absolute top-0 right-0",
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeImage(url)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-close text-black text-lg cursor-pointer",
                                  }),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                      _vm.fileList
                        ? _c(
                            "el-upload",
                            {
                              attrs: {
                                multiple: _vm.multiple,
                                "before-upload": _vm.beforeAvatarUpload,
                                "on-success": _vm.handleMultipleSuccess,
                                "list-type": "picture-card",
                                "on-remove": _vm.remove,
                                limit: _vm.limit,
                                "file-list": _vm.fileList,
                                "show-file-list": !_vm.multiple,
                                action: _vm.action,
                                data: _vm.getFormData(),
                              },
                            },
                            [_c("i", { staticClass: "el-icon-plus" })]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm.type === "text"
                ? _c(
                    "el-upload",
                    {
                      attrs: {
                        accept: "image/jpeg,image/png,image/jpg,image/gif",
                        "before-upload": _vm.beforeAvatarUpload,
                        "show-file-list": false,
                        "on-success": _vm.handleSuccess,
                        action: _vm.action,
                        data: _vm.getFormData(),
                      },
                    },
                    [
                      _vm.$slots.default
                        ? _vm._t("default")
                        : _c("span", { staticClass: "primary font-size-sm" }, [
                            _vm._v(_vm._s(_vm.text)),
                          ]),
                    ],
                    2
                  )
                : _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      class: ["uploader", { miniSize: _vm.miniSize }],
                      attrs: {
                        accept: "image/jpeg,image/png,image/jpg,image/gif",
                        "before-upload": _vm.beforeAvatarUpload,
                        "show-file-list": false,
                        "on-success": _vm.handleSuccess,
                        action: _vm.action,
                        data: _vm.getFormData(),
                      },
                    },
                    [
                      _vm.isLoading
                        ? _c("i", {
                            staticClass: "icon el-icon-loading text-center",
                          })
                        : _vm.value
                        ? _c("img", {
                            staticClass: "image",
                            attrs: { src: _vm.value },
                          })
                        : _c("i", {
                            staticClass: "icon el-icon-plus text-center",
                          }),
                    ]
                  ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }