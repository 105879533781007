var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.info
    ? _c(
        "div",
        _vm._l(_vm.info.form, function (field) {
          return _c("FormItem", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showFormItem(field),
                expression: "showFormItem(field)",
              },
            ],
            key: field.key,
            attrs: { form: _vm.form, field: field },
          })
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }