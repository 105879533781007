<template>
  <div class="text-xl mb-8 flex">
    <slot />
    <Spacer />
    <slot name="action" />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
