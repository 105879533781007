<template>
  <el-header
    class="border-bottom header bg-primary-dark text-white items-center flex"
  >
    <button
      :class="[
        'text-white hover:bg-primary-light w-10 h-10 flex items-center justify-center rounded-full bg-primary nav-toggle',
        { 'nav-toggle-on': navCollapse },
      ]"
      @click="toggleNavCollapse"
    >
      <svg
        class="fill-current w-6 h-6"
        focusable="false"
        viewBox="0 0 24 24"
        aria-hidden="true"
      >
        <g>
          <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
        </g>
      </svg>
    </button>
    <div class="ml-32">
      <h1 class="text-base ml-8 text-white">麦芽儿后台管理</h1>
    </div>
    <div class="flex-grow" />
    <router-link to="/" class="flex">
      <img class="h-12 object-contain logo mr-8" src="@/assets/maiya.jpg" />
    </router-link>
    <el-dropdown v-if="user" trigger="click" @command="handleMenuCommand">
      <span class="el-dropdown-link text-white">
        {{ user.username }}<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="logout">登出</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </el-header>
</template>
<script>
import store from '@/store'
import api from '@/api'
import { mapState } from 'vuex'
export default {
  name: 'Header',
  props: {
    navCollapse: {
      type: Boolean,
      required: true,
    },
  },
  data: function () {
    return {
      store,
    }
  },
  computed: {
    ...mapState(['user']),
  },
  mounted() {
    api.profile().then(res => {
      this.$store.state.user = res.data
    })
  },
  methods: {
    toggleNavCollapse() {
      this.$emit('changeNavCollapse')
    },
    handleMenuCommand(command) {
      switch (command) {
        case 'logout':
          this.logout()
          break
        default:
          throw '没有该命令'
      }
    },
    logout: function () {
      localStorage.clear()
      this.$router.push('/login')
    },
  },
}
</script>
<style lang="scss" scoped>
.nav-toggle {
  transition: transform 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}
.nav-toggle-on {
  transform: rotate(-180deg);
}
.logo {
  border-radius: 12px;
  transition: transform 0.1s cubic-bezier(0, 1.8, 1, 1.8);
}
.logo:hover {
  transform: translateY(5px) rotate(-2deg) scale(1.15);
}
</style>
