var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "header",
        { staticClass: "mb-8" },
        [_c("BreadCrumb", { attrs: { config: _vm.config } })],
        1
      ),
      _c(
        "el-card",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: !_vm.showForm,
              expression: "!showForm",
            },
          ],
        },
        [
          _vm.config.form && _vm.config.form.length
            ? _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "w-2/5",
                  attrs: { "label-width": "130px", model: _vm.form },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("PageForm", {
                    attrs: {
                      fetcher: _vm.fetcher,
                      config: _vm.config,
                      form: _vm.form,
                    },
                    on: {
                      loaded: function ($event) {
                        _vm.showForm = true
                      },
                    },
                  }),
                  _c("SubmitItem", {
                    attrs: { submitting: _vm.submitting, config: _vm.config },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }