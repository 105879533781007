import axios from 'axios'
import http from 'shared/http'

export const getToken = () => localStorage.getItem('token')
import { Message } from 'element-ui'

function getApiPath() {
  switch (process.env.ENV) {
    case 'prod':
      return 'https://api.suwenxuan.cn/manage'
    case 'staging':
      return 'https://api.suwenxuan.net/manage'
    case 'local':
      return 'http://localhost:8000/manage'
    // return 'https://api.suwenxuan.net/manage'
    default:
      return 'http://localhost:8000/manage'
    // return 'https://api.suwenxuan.net/manage'
  }
}
export const apiPath = getApiPath()

export const api = axios.create({
  baseURL: apiPath,
})

api.interceptors.request.use(config => {
  const token = getToken()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

api.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.message == 'Network Error') {
      Message({
        showClose: true,
        message: '网络错误',
        type: 'error',
      })
      throw error
    }
    if (!error.response || error.response.status > 500) {
      Message({
        showClose: true,
        message: '系统异常,请稍后再试！',
        type: 'error',
      })
    } else if (error.response.data) {
      if (error.response.status == 401) {
        localStorage.removeItem('token')
        const router = require('./router').default
        router.push({
          path: '/login',
          query: { redirect: router.history.current.fullPath },
        })
      } else {
        Message({
          showClose: true,
          message: error.response.data.message || error.response.data,
          type: 'error',
        })
      }
    }

    throw error
  }
)

function login(form) {
  return api.post('/login', form)
}
function profile() {
  return api.get('/profile')
}

function makeApi(endPoint, extras) {
  return http.makeApi(api, endPoint, extras)
}

function zanquanCardLevels() {
  return api.get('/zanquan_card_levels')
}

const product = makeApi('/products')
const productCategory = makeApi('/product_category', {
  sort(ids) {
    return api.post('/product_category/sort', {
      ids,
    })
  },
})
const store = makeApi('/store')
const coupon_template = makeApi('/coupon_template')
const member_card_template = makeApi('/member_card_template')
const privilege = makeApi('/privilege')
const staff = makeApi('/staff')
const vip = makeApi('/vip_level')
const drug = makeApi('/drug')
const doctor = makeApi('/doctor')
const service = makeApi('/service')
const servicesSort = makeApi('/services/sort')
const addService = makeApi('/services/add')
const services = makeApi('/services')
const categoryServices = makeApi('/category_services')
const editService = (id, form) => {
  return api.post(`/service/${id}/edit`, form)
}

function acquireUploadToken() {
  return api.post('upload_token')
}

const request = api

export default {
  acquireUploadToken,
  zanquanCardLevels,
  login,
  profile,
  product,
  store,
  staff,
  productCategory,
  vip,
  drug,
  coupon_template,
  member_card_template,
  privilege,
  doctor,
  service,
  servicesSort,
  addService,
  categoryServices,
  services,
  editService,
  api,
  request,
}
