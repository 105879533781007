var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "header",
        { staticClass: "mb-8" },
        [
          _vm.config.navs
            ? _c("BreadCrumb", { attrs: { config: _vm.config } })
            : _c("h4", { staticClass: "text-base" }, [
                _vm._v(" " + _vm._s(_vm.config.title) + " "),
              ]),
        ],
        1
      ),
      _c(
        "el-card",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: !_vm.data,
              expression: "!data",
            },
          ],
        },
        [
          _c(
            "div",
            { staticClass: "flex justify-between" },
            [
              _vm.config.filters && _vm.config.filters.length
                ? _c(
                    "el-form",
                    {
                      attrs: { inline: "", model: _vm.filters },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.search.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._l(_vm.config.filters, function (filter) {
                        return [
                          _c(
                            "el-form-item",
                            {
                              key: filter.field,
                              attrs: {
                                prop: filter.field,
                                label: filter.label,
                                for: filter.field,
                              },
                            },
                            [
                              !filter.type
                                ? _c("el-input", {
                                    attrs: {
                                      id: filter.field,
                                      placeholder: filter.placeholder,
                                    },
                                    model: {
                                      value: _vm.filters[filter.field],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.filters, filter.field, $$v)
                                      },
                                      expression: "filters[filter.field]",
                                    },
                                  })
                                : _vm._e(),
                              filter.type === "datetimerange"
                                ? _c("el-date-picker", {
                                    attrs: {
                                      "default-time": ["00:00:00", "23:59:59"],
                                      "picker-options": _vm.pickerOptions,
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                      type: "datetimerange",
                                      "range-separator": "至",
                                      "start-placeholder": "开始日期",
                                      "end-placeholder": "结束日期",
                                      editable: false,
                                    },
                                    model: {
                                      value: _vm.filters[filter.field],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.filters, filter.field, $$v)
                                      },
                                      expression: "filters[filter.field]",
                                    },
                                  })
                                : _vm._e(),
                              filter.type === "date"
                                ? _c("el-date-picker", {
                                    attrs: {
                                      "picker-options": _vm.pickerOptions,
                                      "value-format": "yyyy-MM-dd",
                                      type: "date",
                                      editable: false,
                                    },
                                    model: {
                                      value: _vm.filters[filter.field],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.filters, filter.field, $$v)
                                      },
                                      expression: "filters[filter.field]",
                                    },
                                  })
                                : _vm._e(),
                              filter.type === "select" && _vm.isResolved(filter)
                                ? [
                                    Array.isArray(filter.options)
                                      ? _c(
                                          "el-select",
                                          {
                                            model: {
                                              value: _vm.filters[filter.field],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.filters,
                                                  filter.field,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "filters[filter.field]",
                                            },
                                          },
                                          _vm._l(
                                            filter.options,
                                            function (option) {
                                              return _c("el-option", {
                                                key: option.id,
                                                attrs: {
                                                  value: option.id,
                                                  label: option.name,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        )
                                      : _c(
                                          "el-select",
                                          {
                                            model: {
                                              value: _vm.filters[filter.field],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.filters,
                                                  filter.field,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "filters[filter.field]",
                                            },
                                          },
                                          _vm._l(
                                            filter.options,
                                            function (key, value) {
                                              return _c("el-option", {
                                                key: value,
                                                attrs: {
                                                  value: value,
                                                  label: key,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      }),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                "native-type": "submit",
                              },
                            },
                            [_vm._v("查询")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { "native-type": "button" },
                              on: { click: _vm.reset },
                            },
                            [_vm._v("重置")]
                          ),
                        ],
                        1
                      ),
                      _vm.config.exportApis
                        ? _vm._l(_vm.config.exportApis, function (exporter) {
                            return _c(
                              "el-form-item",
                              { key: exporter.exportApi },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      loading:
                                        _vm.exportingExcels[
                                          exporter.exportTitle
                                        ],
                                      type: "primary",
                                      plain: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.exportExcle(exporter)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(exporter.exportTitle || "导出")
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          })
                        : _vm._e(),
                      _vm.config.exportApi
                        ? _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    loading:
                                      _vm.exportingExcels[
                                        _vm.config.exportTitle
                                      ],
                                    type: "primary",
                                    plain: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.exportExcle(_vm.config)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.config.exportTitle || "导出")
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _c(
                    "a",
                    [
                      _vm.config.needSort
                        ? _c(
                            "el-button",
                            {
                              staticClass: "mr-2",
                              attrs: { type: "primary" },
                              on: { click: _vm.sortData },
                            },
                            [_vm._v("排序")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._l(_vm.config.actions, function (action) {
                    return _c(
                      "router-link",
                      {
                        key: action.title,
                        staticClass: "mr-2",
                        attrs: {
                          to: { name: action.route, params: action.params },
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              plain: action.plain,
                              type: action.type || "primary",
                            },
                          },
                          [_vm._v(_vm._s(action.title))]
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _vm._t("totalData", null, { income: _vm.info }),
          _vm.data
            ? _c(
                "el-table",
                { attrs: { data: _vm.data, stripe: "" } },
                [
                  _vm._l(_vm.config.table.fields, function (field) {
                    return [
                      _c("el-table-column", {
                        key: field.key,
                        attrs: {
                          label: field.label,
                          prop: field.key,
                          sortable: field.sortable,
                          width: field.width,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ row }) {
                                return [
                                  field.render
                                    ? [
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              field.render(row)
                                            ),
                                          },
                                        }),
                                      ]
                                    : field.type == "boolean"
                                    ? [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.deepGet(row, field.key) ===
                                                true
                                                ? field.trueText
                                                : field.falseText
                                            ) +
                                            " "
                                        ),
                                      ]
                                    : typeof row[field.key] == "boolean"
                                    ? [
                                        row[field.key]
                                          ? [
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-check text-green",
                                              }),
                                            ]
                                          : [
                                              _c("i", {
                                                staticClass: "el-icon-close",
                                              }),
                                            ],
                                      ]
                                    : _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              (
                                                field.formatter ||
                                                function (d) {
                                                  return d
                                                }
                                              )(_vm.deepGet(row, field.key))
                                            ) +
                                            " "
                                        ),
                                      ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ]
                  }),
                  _vm.config.table.operations.length
                    ? _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          width: 90 * _vm.config.table.operations.length,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ row }) {
                                return _vm._l(
                                  _vm.config.table.operations,
                                  function (op, index) {
                                    return _c(
                                      "span",
                                      { key: index },
                                      [
                                        op.type == "DETAIL"
                                          ? _c(
                                              "router-link",
                                              {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  to: _vm.rowRoute(
                                                    op.route,
                                                    row
                                                  ),
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-button",
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        size: "mini",
                                                        type: "primary",
                                                      },
                                                    },
                                                    "el-button",
                                                    op.props,
                                                    false
                                                  ),
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(op.title) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        op.type == "EDIT"
                                          ? _c(
                                              "router-link",
                                              {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  to: _vm.rowRoute(
                                                    op.route,
                                                    row
                                                  ),
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-button",
                                                  { attrs: { size: "mini" } },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(op.title) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        op.type == "MODAL"
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  size: "mini",
                                                  type: "primary",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.openModal(
                                                      row,
                                                      op
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(_vm._s(op.title))]
                                            )
                                          : _vm._e(),
                                        op.type == "DELETE" && row.deletable
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  size: "mini",
                                                  type: "danger",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteItem(
                                                      row,
                                                      op
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(_vm._s(op.title))]
                                            )
                                          : _vm._e(),
                                        op.type == "ACTION" &&
                                        (!op.show || op.show(row))
                                          ? _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  size: "mini",
                                                  type: "primary",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.updateItem(
                                                      row,
                                                      op
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(_vm._s(op.title))]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  }
                                )
                              },
                            },
                          ],
                          null,
                          false,
                          2817653159
                        ),
                      })
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _vm.isModalOpen
            ? _c(
                "el-dialog",
                {
                  attrs: {
                    title: _vm.modalProps.operation.title,
                    visible: _vm.isModalOpen,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.isModalOpen = $event
                    },
                  },
                },
                [
                  _c(_vm.modalComponent, {
                    tag: "component",
                    attrs: {
                      data: _vm.modalProps.data,
                      operation: _vm.modalProps.operation,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "flex justify-center" },
            [
              _c("Pagination", {
                attrs: {
                  "current-page": _vm.query.page,
                  "page-size": _vm.query.per_page,
                  total: _vm.total,
                },
                on: {
                  "size-change": _vm.onPageSizeChange,
                  "current-change": _vm.onPageChange,
                },
              }),
            ],
            1
          ),
          _vm.isSortVisible
            ? _c(
                "el-dialog",
                {
                  attrs: { title: "排序", visible: _vm.isSortVisible },
                  on: {
                    "update:visible": function ($event) {
                      _vm.isSortVisible = $event
                    },
                  },
                },
                [
                  _c("SortList", {
                    attrs: { list: _vm.data },
                    on: { close: _vm.close, saveSortIds: _vm.saveSortIds },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }