var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.config.navs
    ? _c(
        "el-breadcrumb",
        { attrs: { "separator-class": "el-icon-arrow-right" } },
        [
          _vm._l(_vm.config.navs, function (nav) {
            return _c(
              "el-breadcrumb-item",
              {
                key: _vm.normalizeRoute(nav.to).name,
                attrs: { to: _vm.normalizeRoute(nav.to) },
              },
              [_vm._v(" " + _vm._s(nav.title) + " ")]
            )
          }),
          _c("el-breadcrumb-item", [_vm._v(_vm._s(_vm.config.title))]),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }