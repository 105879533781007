function makeApi(api, endPoint, extras) {
  return {
    list(params) {
      return api.get(endPoint, { params })
    },
    post(form) {
      return api.post(endPoint, form)
    },
    delete(id) {
      return api.delete(`${endPoint}/${id}`)
    },
    detail(id) {
      return api.get(`${endPoint}/${id}`)
    },
    put(id, form) {
      return api.put(`${endPoint}/${id}`, form)
    },
    patch(id, form) {
      return api.patch(`${endPoint}/${id}`, form)
    },
    all(params) {
      return api.get(`${endPoint}/all`, { params })
    },
    ...extras,
  }
}

export default {
  makeApi,
}
