<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
body {
  font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
    'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
}
button:focus {
  outline: 0;
}
.el-table {
  margin-bottom: 32px;
  margin-top: 32px;
}
.el-table th {
  background: #f5f7fa;
}
.el-loading-spinner {
  left: 50%;
}
.main {
  background-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  border-radius: 4px;
}
</style>
