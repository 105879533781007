import page from 'shared/pages'
import List from './List'
export default [
  {
    path: '',
    name: 'product_list',
    component: page.createList(List),
  },
  {
    path: 'new',
    name: 'product_new',
    component: () => import('./New'),
  },
  {
    path: ':id',
    name: 'product_detail',
    component: () => import('./Detail'),
    props: true,
  },
  {
    path: ':id/edit',
    name: 'product_edit',
    component: () => import('./Edit'),
    props: true,
  },
]
