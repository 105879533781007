
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    reset: {
      required: true,
    },
  },
})
