var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeTab,
            callback: function ($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab",
          },
        },
        _vm._l(_vm.config.tabs, function (tab) {
          return _c(
            "el-tab-pane",
            { key: tab.label, attrs: { label: tab.label, name: tab.label } },
            [
              _c(_vm.tabComponent(tab), {
                tag: "component",
                attrs: { fields: tab.fields, data: _vm.data },
              }),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }