import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import Root from './pages/Root'
import DashBoard from './pages/Dashboard'
import RouterView from './RouterView'
import ProductRoutes from './pages/product/routes'
import CategoryRoutes from './pages/category/routes'
import StoreRoutes from './pages/store/routes'
import StaffRoutes from './pages/staff/routes'
import VipRoutes from './pages/vip/routes'
import DrugRoutes from './pages/drug/routes'
import CouponTemplateRoutes from './pages/coupon_template/routes'
import MemberCardTemplateRoutes from './pages/member_card_template/routes'
import MemberCardOrderRoutes from './pages/member_card_order/routes'
import RechargeOrderRoutes from './pages/recharge_order/routes'
import ConsumeRecordRoutes from './pages/consume_record/routes'
import PrivilegeRoutes from './pages/privilege/routes'
import ServiceRoutes from './pages/services/routes'
import GoodsRoutes from './pages/goods/routes'
import GoodsOrderRoutes from './pages/goods_order/routes'
import UserRoutes from './pages/user/routes'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Root,
      children: [
        {
          path: '/',
          component: DashBoard,
        },
        {
          path: '/product',
          component: RouterView,
          children: ProductRoutes,
        },
        {
          path: '/category',
          component: RouterView,
          children: CategoryRoutes,
        },
        {
          path: '/store',
          component: RouterView,
          children: StoreRoutes,
        },
        {
          path: '/staff',
          component: RouterView,
          children: StaffRoutes,
        },
        {
          path: '/vip',
          component: RouterView,
          children: VipRoutes,
        },
        {
          path: '/drug',
          component: RouterView,
          children: DrugRoutes,
        },
        {
          path: '/coupon_template',
          component: RouterView,
          children: CouponTemplateRoutes,
        },
        {
          path: '/member_card_template',
          component: RouterView,
          children: MemberCardTemplateRoutes,
        },
        {
          path: '/member_card_orders',
          component: RouterView,
          children: MemberCardOrderRoutes,
        },
        {
          path: '/recharge_orders',
          component: RouterView,
          children: RechargeOrderRoutes,
        },
        {
          path: '/consume_records',
          component: RouterView,
          children: ConsumeRecordRoutes,
        },
        {
          path: '/privilege',
          component: RouterView,
          children: PrivilegeRoutes,
        },
        {
          path: '/services',
          component: RouterView,
          children: ServiceRoutes,
        },
        {
          path: '/goods',
          component: RouterView,
          children: GoodsRoutes,
        },
        {
          path: '/goods_order',
          component: RouterView,
          children: GoodsOrderRoutes,
        },
        {
          path: '/user',
          component: RouterView,
          children: UserRoutes,
        },
      ],
    },

    {
      path: '/login',
      name: 'login',
      component: () => import('./pages/Login.vue'),
    },
    {
      path: '/404',
      name: '404',
      component: () => import('./pages/NotFound.vue'),
    },
    {
      path: '*',
      redirect: {
        name: '404',
      },
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (
    to.matched.some(record => record.path === '*') ||
    ['/login'].includes(to.path) ||
    store.state.isLogin
  ) {
    next()
  } else {
    next({
      path: '/login',
      query: { redirect: to.fullPath },
    })
  }
})

export default router
