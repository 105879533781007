import api from '@/api'

export default {
  title: '医生特权',
  api: api.privilege.list,
  filters: [
    {
      label: '名称',
      field: 'name',
      placeholder: '',
    },
  ],
  actions: [
    {
      title: '创建特权',
      route: 'privilege_new',
    },
  ],
  table: {
    fields: [
      {
        key: 'doctor.name',
        label: '医生',
      },
      {
        key: 'name',
        label: '名称',
      },
      {
        key: 'reduce_amount',
        label: '减免金额',
      },
    ],
    operations: [
      {
        title: '查看',
        type: 'DETAIL',
        route: 'privilege_detail',
      },
      {
        title: '编辑',
        type: 'EDIT',
        route: 'privilege_edit',
      },
      {
        title: '删除',
        api: api.privilege.delete,
        type: 'DELETE',
      },
    ],
  },
}
