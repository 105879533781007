export default {
  computed: {
    submitAction() {
      const actions = this.config.actions
      if (actions.length == 1) {
        return actions[0]
      }
      return actions.find(action => action.submit)
    },
  },
  methods: {
    async submit() {
      this.submitting = true
      try {
        await this.$validateForm()
      } catch (error) {
        this.submitting = false
        return
      }
      var api
      if (this.form.id) {
        api = () => this.submitAction.api(this.form.id, this.form)
      } else {
        api = () => this.submitAction.api(this.form)
      }
      api().then(
        () => {
          if (this.config.to) {
            let to = this.config.to
            if (typeof to == 'string') {
              to = { name: this.config.to }
            }

            this.$router.push(to, () => {
              this.$message({
                message: '操作成功',
                type: 'success',
              })
            })
            return
          }
          this.$message({
            message: '操作成功',
            type: 'success',
          })
        },
        () => {
          this.submitting = false
        }
      )
    },
  },
}
