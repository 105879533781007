export default [
  {
    path: '',
    name: 'OrderList',
    component: () => import('./List'),
  },

  {
    path: ':id',
    name: 'OrderDetail',
    component: () => import('./Detail'),
    props: true,
  },
]
