var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.config.actions, function (action) {
      return _c(
        "el-form-item",
        { key: action.title },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                loading:
                  action.title == _vm.submitAction.title && _vm.submitting,
                "native-type":
                  action.title == _vm.submitAction.title ? "submit" : "",
              },
            },
            [_vm._v(_vm._s(action.title))]
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }