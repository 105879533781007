import api from '@/api'

export default {
  title: '商品',
  api: api.product.list,
  filters: [
    {
      label: '商品名称',
      field: 'name',
      placeholder: '',
    },
  ],
  actions: [
    {
      title: '创建主商品',
      route: 'product_new',
    },
  ],
  table: {
    fields: [
      {
        key: 'name',
        label: '商品名称',
        sortable: true,
      },
      {
        key: 'number',
        label: '商品编号',
      },
      {
        key: 'category.name',
        label: '商品分类',
      },
    ],
    operations: [
      {
        title: '查看',
        type: 'DETAIL',
        route: 'product_detail',
      },
      {
        title: '编辑',
        type: 'EDIT',
        route: 'product_edit',
      },
      {
        title: '删除',
        api: api.product.delete,
        type: 'DELETE',
      },
    ],
  },
}
