var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "v-spinner",
    },
    [_c("div", { staticClass: "v-clip", style: _vm.spinnerStyle })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }