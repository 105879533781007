import { createList } from 'shared/pages'
export default [
  {
    path: '',
    name: 'vip_list',
    component: () =>
      import('./List').then(config => createList(config.default)),
  },
  {
    path: 'new',
    name: 'vip_new',
    component: () => import('./New'),
  },
  {
    path: ':id',
    name: 'vip_detail',
    component: () => import('./Detail'),
    props: true,
  },
  {
    path: ':id/edit',
    name: 'vip_edit',
    component: () => import('./Edit'),
    props: true,
  },
]
