
import helpers from 'shared/helpers'
export default {
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  methods: {
    normalizeRoute(route) {
      return helpers.normalizeRoute(route)
    },
  },
}
